<template>
  <div>
    <v-dialog v-model="OptionSetDialog" width="800px" persistent>									
  <v-card class="slightlypadded">									
    <v-img src='@/assets/RABaseBG.jpeg'>									
      <v-card-title class="headline blue-grey darken-2 white--text">									
                {{EditOptionSet.Name}} - Configuration <v-layout class="justify-end">									
      <v-btn fab @click="SaveOptionSets(true)" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelOptionSetDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn>									
      </v-layout>									
                </v-card-title>									
      <v-list>                                                									
        <v-list-item>                                      									
        <v-list-item-content>									
          <v-switch									
              v-model="EditOptionSet.Secondary"									
              class="mx-2"									
              label="Secondary Field"									
            ></v-switch>									
            </v-list-item-content>									
          <v-list-item-content>									
            <v-switch									
              v-model="EditOptionSet.IsFilter"									
              class="mx-2"									
              label="Filter Field"									
            ></v-switch>									
          </v-list-item-content>									
          <v-list-item-content>									
          <v-switch									
            v-model="EditOptionSet.IsMandatory"									
            class="mx-2"									
            label="Mandatory Field"									
          ></v-switch>									
          </v-list-item-content>                                    									
        </v-list-item>									
        </v-list>									
        <v-select item-text="Name" :items="EditOptionSet.Options" return-object v-model="EditOptionSet.DefaultOption" label="Default Option">									
        </v-select>									
  <v-layout row  color="rgb(236, 60, 126, 0.7)">									
      									
    <v-col cols="4">									
      <v-list>									
        <v-list-item>									
          <v-list-item-content class="listtitle">									
          Options									
          </v-list-item-content>									
          <v-list-item-action>									
            <v-menu v-model="NewOptionDialog"									
          :close-on-content-click="false"                            									
          transition="scale-transition"                            									
          >									
          <template v-slot:activator="{ on }">									
              <v-btn fab icon v-on="on"><v-icon>mdi-plus-thick</v-icon></v-btn>									
            </template>									
            <v-card width="400px">									
              <v-card-title>									
                New Option for {{EditOptionSet.Name}}									
                </v-card-title>									
                <v-text-field v-model="OptionName" label="Name"></v-text-field>                                                        									
                  <v-card-actions>									
                      <v-btn v-if="OptionName !== ''" @click="AddOptionSetOption(OptionName)">Save</v-btn><v-btn @click="ClearNewOption()">Cancel</v-btn>									
                  </v-card-actions>									
              </v-card>									
          </v-menu>									
            </v-list-item-action>									
          </v-list-item>									
        </v-list>									
        									
        <v-list>									
        <v-list-item v-for="optionsetoption in EditOptionSet.Options" :key="optionsetoption.itemObjKey" class="listoutline" @click="OptionNameSelect(optionsetoption)">									
          <v-list-item-content>									
            {{optionsetoption.Name}}									
            </v-list-item-content>									
          </v-list-item>									
        </v-list>									
      </v-col>									
      <v-col>									
        <!-- <v-scroll-y-transition mode="in-out" v-if="EditOptionSet.Name"> -->									
									
            <v-card outlined  height="100%" width="100%" color="rgb(255, 255, 255, 0.9)" v-if="EditOptionSet.Name">                                									
                <v-card-title class="headline blue-grey darken-2 white--text" v-if="Option.Name">									
                Option - {{Option.Name}}									
                  <!-- <v-spacer></v-spacer>									
                  <v-switch dark									
                    v-model="Option.IsDefault"									
                    class="mx-2"									
                    label="Is Default"									
                  ></v-switch> -->									
                </v-card-title>									
                <v-card-text >									
                  <p v-if="Option.ID">Option ID: {{Option.ID}}</p>									
                  <v-text-field label="Option Name" v-model="Option.Name" v-if="Option.Name"></v-text-field>									
                  <v-layout class="justify-end" v-if="Option.Name"><v-icon @click="DeleteOption(Option)" color="red">mdi-delete-forever</v-icon></v-layout>                                                       									
                </v-card-text>									
                <v-card-text v-if="typeof Option.Name === 'undefined'">									
                  Add an option to the left<span v-if="EditOptionSet.Options.length>0">, or select an existing option to change its name</span>...                                                    									
                </v-card-text>									
                                        									
            </v-card>									
                                									
            <!-- </v-scroll-y-transition> -->									
        </v-col>									
    </v-layout>									
    									
    <!-- <v-layout class="justify-end">									
      <v-btn fab @click="SaveGlobalOptionSets()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn>									
      </v-layout> -->									
      <v-layout class="justify-end">
          									
      <v-btn fab @click="Threepropemit('DeleteFieldOffTabSectionANDRecordType',EditedTab,EditSection,EditOptionSet)" icon><v-icon color="red" x-large>mdi-delete-forever</v-icon></v-btn>									
      </v-layout>									
      </v-img>									
      									
    </v-card>									
  </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','EditOptionSet','OptionSetDialog'],
    components: {},
    data() {
        return {								
            								
            OptionName: '',									
            Option: {},	
            NewOptionDialog: false,

        }
    },	
    computed:{
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        //alert('blelelelele')
    },
    methods:{
        DeleteOption(option){
        let index = this.EditOptionSet.Options.indexOf(option)
        confirm('Are you sure you want to remove this option set? There is no going back, and any existing data with this value assigned will have no data') && this.EditOptionSet.Options.splice(index,1)
        this.Option = {}
        this.SaveOptionSets(true)
        },
        SaveOptionSets(bool){
            this.$emit('SaveOptionSets',bool)
        },
        Onepropemit(MethodName,prop){
            //console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
        ClearNewOption(){
            this.NewOptionDialog = false
            this.OptionName = ''
        },								
        AddOptionSetOption(OptionName){									
        if(typeof this.EditOptionSet.lastassignedinteger === 'undefined'){									
            this.EditOptionSet.lastassignedinteger = 1000000									
                                                
        }      									
        let integer = this.EditOptionSet.lastassignedinteger-1+2									
                                            
        let NewOption = {Name: OptionName, ID: integer}									
        this.EditOptionSet.Options.push(NewOption)									
        this.OptionName = ''									
        this.EditOptionSet.lastassignedinteger = integer									
        },									
        OptionNameSelect(option){									
        this.Option = option									
        },									
        CancelOptionSetDialog(){
            this.Option = {}	
            this.$emit('NoPropsemit','CancelOptionSetDialog')						
        },									
        
    }
}
</script>

<style>

</style>



